
import {FormQueryQuery} from "../types/types";
import gql from "graphql-tag";

export const FormQuery = gql`
    query FormQuery($handle: [String]) {
        form: formieForm(handle: $handle) {
            id
            title
            handle
            siteId

            csrfToken {
                name
                value
            }

            captchas {
                handle
                name
                value
            }

            settings {
                displayFormTitle
                displayPageTabs
                displayCurrentPageTitle
                displayPageProgress
                submitMethod
                submitAction
                submitActionTab
                submitActionFormHide
                submitActionMessageHtml
                submitActionMessageTimeout
                redirectUrl
                errorMessageHtml
                loadingIndicator
                loadingIndicatorText
                validationOnSubmit
                validationOnFocus
                defaultLabelPosition
                defaultInstructionsPosition
                progressPosition

                integrations {
                    settings
                }

                redirectEntry {
                    id
                    uri
                    slug
                    sectionHandle
                }
            }

            pages {
                label
                id

                settings {
                    submitButtonLabel
                    backButtonLabel
                    showBackButton
                    buttonsPosition
                }

                rows {
                    rowFields {
                        id
                        label
                        handle
                        instructions
                        required
                        type
                        displayName
                        typeName
                        inputTypeName
                        placeholder
                        errorMessage
                        labelPosition
                        instructionsPosition
                        cssClasses
                        enableConditions
                        conditions
                        enableContentEncryption
                        visibility
                        containerAttributes {
                            label
                            value
                        }
                        inputAttributes {
                            label
                            value
                        }
                        ... on Field_Address {
                            nestedRows {
                                rowFields {
                                    ... on Field_Address1 {
                                        label
                                        enabled
                                        inputAttributes {
                                            label
                                            value
                                        }
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                    }
                                    ... on Field_Address2 {
                                        label
                                        enabled
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                    }
                                    ... on Field_Address3 {
                                        id
                                        label
                                        enabled
                                        visibility
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                        required
                                    }
                                    ... on Field_AddressCity {
                                        id
                                        label
                                        enabled
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                    }
                                    ... on Field_AddressZip {
                                        label
                                        enabled
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                    }
                                    ... on Field_AddressState {
                                        label
                                        enabled
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                    }
                                    ... on Field_AddressCountry {
                                        label
                                        enabled
                                        visibility
                                        required
                                        placeholder
                                        prePopulate
                                        defaultValue
                                        errorMessage
                                        options {
                                            label
                                            value
                                            isDefault
                                            disabled
                                            isOptgroup
                                        }
                                    }
                                }
                            }
                        }
                        ... on Field_Agree {
                            defaultState
                            checkedValue
                            uncheckedValue
                            descriptionHtml
                            errorMessage
                        }
                        ... on Field_Categories {
                            displayType
                            categories {
                                id
                                title
                            }
                        }
                        ... on Field_Checkboxes {
                            defaultValue
                            layout
                            options {
                                label
                                value
                            }
                        }
                        ... on Field_Dropdown {
                            multi
                            defaultValue
                            options {
                                label
                                value
                            }
                        }
                        ... on Field_Email {
                            defaultValue
                        }
                        ... on Field_Entries {
                            displayType
                            entries {
                                id
                                title
                            }
                        }
                        ... on Field_FileUpload {
                            defaultValue
                            sizeMinLimit
                            sizeLimit
                            allowedKinds
                            limitFiles
                            source
                            labelSource
                        }
                        ... on Field_Heading {
                            headingSize
                        }
                        ... on Field_Hidden {
                            defaultValue
                            defaultOption
                            queryParameter
                            cookieName
                        }
                        ... on Field_Html {
                            htmlContent
                        }
                        ... on Field_MultiLineText {
                            defaultValue
                        }
                        ... on Field_Number {
                            min
                            max
                        }
                        ... on Field_Phone {
                            defaultValue
                            countryEnabled
                            countryDefaultValue
                            countryOptions {
                                label
                                value
                            }
                        }
                        ... on Field_Radio {
                            layout
                            options {
                                label
                                value
                            }
                        }
                        ... on Field_Recipients {
                            displayType
                            defaultValue
                            options {
                                label
                                value
                            }
                        }
                        ... on Field_Section {
                            borderStyle
                            borderWidth
                            borderColor
                        }
                        ... on Field_SingleLineText {
                            defaultValue
                        }
                        ... on Field_Table {
                            columns {
                                heading
                                handle
                                type
                            }
                        }
                        ... on Field_Tags {
                            displayType
                            tags {
                                id
                                title
                            }
                        }
                        ... on Field_Users {
                            displayType
                            users {
                                id
                                fullName
                            }
                        }
                        ... on Field_ExtendableEntries{
                            entries{
                                title
                                sectionHandle
                                id
                            }
                        }

                        ... on Field_Repeater {
                            fields {
                                id
                                label
                                handle
                                instructions
                                required
                                type
                                displayName
                                typeName
                                inputTypeName
                                placeholder
                                errorMessage
                                labelPosition
                                instructionsPosition
                                cssClasses
                                enableConditions
                                conditions
                                enableContentEncryption
                                visibility
                                containerAttributes {
                                    label
                                    value
                                }
                                inputAttributes {
                                    label
                                    value
                                }
                                ... on Field_Address {
                                    nestedRows {
                                        rowFields {
                                            ... on Field_Address1 {
                                                label
                                                enabled
                                                inputAttributes {
                                                    label
                                                    value
                                                }
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_Address2 {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_Address3 {
                                                id
                                                label
                                                enabled
                                                visibility
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                                required
                                            }
                                            ... on Field_AddressCity {
                                                id
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressZip {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressState {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressCountry {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                                options {
                                                    label
                                                    value
                                                    isDefault
                                                    disabled
                                                    isOptgroup
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on Field_Agree {
                                    defaultState
                                    checkedValue
                                    uncheckedValue
                                    descriptionHtml
                                    errorMessage
                                }
                                ... on Field_Categories {
                                    displayType
                                    categories {
                                        id
                                        title
                                    }
                                }
                                ... on Field_Checkboxes {
                                    defaultValue
                                    layout
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Dropdown {
                                    multi
                                    defaultValue
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Email {
                                    defaultValue
                                }
                                ... on Field_Entries {
                                    displayType
                                    entries {
                                        id
                                        title
                                    }
                                }
                                ... on Field_FileUpload {
                                    defaultValue
                                    sizeMinLimit
                                    sizeLimit
                                    allowedKinds
                                    limitFiles
                                    source
                                    labelSource
                                }
                                ... on Field_Heading {
                                    headingSize
                                }
                                ... on Field_Hidden {
                                    defaultValue
                                    defaultOption
                                    queryParameter
                                    cookieName
                                }
                                ... on Field_Html {
                                    htmlContent
                                }
                                ... on Field_MultiLineText {
                                    defaultValue
                                }
                                ... on Field_Number {
                                    min
                                    max
                                }
                                ... on Field_Phone {
                                    defaultValue
                                    countryEnabled
                                    countryDefaultValue
                                    countryOptions {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Radio {
                                    layout
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Recipients {
                                    displayType
                                    defaultValue
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Section {
                                    borderStyle
                                    borderWidth
                                    borderColor
                                }
                                ... on Field_SingleLineText {
                                    defaultValue
                                }
                                ... on Field_Table {
                                    columns {
                                        heading
                                        handle
                                        type
                                    }
                                }
                                ... on Field_Tags {
                                    displayType
                                    tags {
                                        id
                                        title
                                    }
                                }
                                ... on Field_Users {
                                    displayType
                                    users {
                                        id
                                        fullName
                                    }
                                }
                                ... on Field_ExtendableEntries{
                                    entries{
                                        title,
                                        sectionHandle
                                        id
                                    }
                                }
                            }
                        }

                        ... on Field_Group {
                            fields {
                                id
                                label
                                handle
                                instructions
                                required
                                type
                                displayName
                                typeName
                                inputTypeName
                                placeholder
                                errorMessage
                                labelPosition
                                instructionsPosition
                                cssClasses
                                enableConditions
                                conditions
                                enableContentEncryption
                                visibility
                                containerAttributes {
                                    label
                                    value
                                }
                                inputAttributes {
                                    label
                                    value
                                }
                                ... on Field_Address {
                                    nestedRows {
                                        rowFields {
                                            ... on Field_Address1 {
                                                label
                                                enabled
                                                inputAttributes {
                                                    label
                                                    value
                                                }
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_Address2 {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_Address3 {
                                                id
                                                label
                                                enabled
                                                visibility
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                                required
                                            }
                                            ... on Field_AddressCity {
                                                id
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressZip {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressState {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                            }
                                            ... on Field_AddressCountry {
                                                label
                                                enabled
                                                visibility
                                                required
                                                placeholder
                                                prePopulate
                                                defaultValue
                                                errorMessage
                                                options {
                                                    label
                                                    value
                                                    isDefault
                                                    disabled
                                                    isOptgroup
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on Field_Agree {
                                    defaultState
                                    checkedValue
                                    uncheckedValue
                                    descriptionHtml
                                    errorMessage
                                }
                                ... on Field_Categories {
                                    displayType
                                    categories {
                                        id
                                        title
                                    }
                                }
                                ... on Field_Checkboxes {
                                    defaultValue
                                    layout
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Dropdown {
                                    multi
                                    defaultValue
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Email {
                                    defaultValue
                                }
                                ... on Field_Entries {
                                    displayType
                                    entries {
                                        id
                                        title
                                    }
                                }
                                ... on Field_FileUpload {
                                    defaultValue
                                    sizeMinLimit
                                    sizeLimit
                                    allowedKinds
                                    limitFiles
                                    source
                                    labelSource
                                }
                                ... on Field_Heading {
                                    headingSize
                                }
                                ... on Field_Hidden {
                                    defaultValue
                                    defaultOption
                                    queryParameter
                                    cookieName
                                }
                                ... on Field_Html {
                                    htmlContent
                                }
                                ... on Field_MultiLineText {
                                    defaultValue
                                }
                                ... on Field_Number {
                                    min
                                    max
                                }
                                ... on Field_Phone {
                                    defaultValue
                                    countryEnabled
                                    countryDefaultValue
                                    countryOptions {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Radio {
                                    layout
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Recipients {
                                    displayType
                                    defaultValue
                                    options {
                                        label
                                        value
                                    }
                                }
                                ... on Field_Section {
                                    borderStyle
                                    borderWidth
                                    borderColor
                                }
                                ... on Field_SingleLineText {
                                    defaultValue
                                }
                                ... on Field_Table {
                                    columns {
                                        heading
                                        handle
                                        type
                                    }
                                }
                                ... on Field_Tags {
                                    displayType
                                    tags {
                                        id
                                        title
                                    }
                                }
                                ... on Field_Users {
                                    displayType
                                    users {
                                        id
                                        fullName
                                    }
                                }
                                ... on Field_ExtendableEntries{
                                    entries{
                                        title
                                        sectionHandle
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SubmissionMutation = (formHandle:string, mutationValues:any, mutationTypes:any)=>{
    const mutationHandle = `save_${formHandle}_Submission`;
    return gql`
        mutation FormMutation(${mutationTypes}) {
            ${mutationHandle}(${mutationValues}) {
                id
            }
        }
    `;
}
